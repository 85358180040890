<template>
  <div class="create_chanel">
    <c-box d="flex" justify-content="space-between">
      <h2>Create Channel</h2>

      <c-button
        size="md"
        variant-color="vc-orange"
        variant="link"
        @click="createChannel"
      >
        Create
      </c-button>
    </c-box>

    <div class="create_chanel__name">
      <span class="image_span" @click="openImageChange">
        <img :src="channelFile.url" alt="" />
        <!-- <i class="fas fa-images" v-show="!channelFile.url"></i> -->
        <input
          type="file"
          ref="channelFileInput"
          @change="onImageChange"
          style="display: none"
        />
      </span>

      <div>
        <input
          placeholder="Enter Channel Name"
          type="text"
          v-model="channel.name"
          style="background: transparent"
          class="chan_name"
        />
        <div class="mt-5">
          <textarea
            placeholder="Description"
            class="text_area"
            v-model="channel.description"
          ></textarea>
        </div>
      </div>
    </div>

    <c-box mt="5">
      <c-checkbox
        value="OPEN"
        variant-color="vc-orange"
        :isChecked="isPublic"
        v-model="isPublic"
        @change="onchangePublic"
        >Public</c-checkbox
      >
      <c-checkbox
        class="ml-5"
        value="CLOSE"
        :isChecked="isPrivate"
        @change="onchangePrivate"
        v-model="isPrivate"
        variant-color="vc-orange"
        >Private</c-checkbox
      >
    </c-box>
    <br />
    <div style="text-align: center" v-if="loading">
      <c-spinner ml="2" color="orange" thickness="2px" />
    </div>
    <c-box class="create_chanel__members" mt="5" v-else>
      <c-box d="flex" alignItems="center" padding="4">
        <i class="fas fa-user-plus"></i>
        <p @click="showMembersModal = true">
          Add Members ({{ participants.length }})
        </p>
      </c-box>
      <div v-if="!isUsersEmpty">
        <hr />

        <c-box
          v-for="(participant, index) in participants"
          :key="index"
          padding="5"
          d="flex"
          alignItems="center"
          justify-content="space-between"
          class="user_container"
        >
          <div class="user" @click="sendMessage(participant)">
            <img class="member" :src="participant.profilePhoto" />
            <div class="details">
              <h3>{{ participant.firstname }} {{ participant.lastname }}</h3>
              <p>{{ participant.email }}</p>
            </div>
          </div>

          <c-button
            size="md"
            variant-color="vc-orange"
            variant="link"
            @click="removeParticipants(participant.id)"
          >
            Remove
          </c-button>
        </c-box>
      </div>

      <div style="text-align: center; color: grey" v-else>
        <p>No participants added</p>
      </div>
    </c-box>

    <c-modal
      :is-open="showMembersModal"
      :on-close="closeMembersModal"
      is-centered
      size="lg"
      style="border-radius: 10px"
    >
      <c-modal-content ref="content">
        <c-modal-header>Add Members</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <c-box mt="5">
            <UsersLists
              type="newUsers"
              :show-checkbox="true"
              heightId="fixedHeight"
              :searchable="true"
              @usersSelected="addParticipants"
            />
          </c-box>
        </c-modal-body>
        <c-modal-footer
          d="flex"
          alignItems="center"
          justify-content="space-between"
        >
          <c-button @click="showMembersModal = false">Cancel</c-button>
          <c-button
            variant-color="vc-orange"
            mr="3"
            @click="showMembersModal = false"
          >
            Next
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import store from '@/store';
import UsersLists from './components/UsersLists.vue';
import { createChannel } from '@/services/messages.js';
import { uploadFile } from '@/services/common';

export default {
  components: {
    UsersLists,
  },
  data() {
    return {
      loading: false,
      isPrivate: false,
      isPublic: true,
      showMembersModal: false,
      channelFile: {
        url: 'https://placehold.co/400x400',
        file: null,
      },
      channel: {
        name: '',
        type: 'OPEN',
        description: '',
        logoUrl: '',
        participants: {
          data: [
            {
              participant_id: '',
            },
          ],
        },
      },
      participants: [],
    };
  },
  computed: {
    isUsersEmpty() {
      return this.participants.length === 0;
    },
  },
  methods: {
    closeMembersModal() {
      this.showMembersModal = false;
    },
    async createChannel() {
      this.loading = true;
      const participantIds = this.participants.map(
        (participant) => participant.id
      );

      participantIds.push(store.state.auth.user.id);

      let logoUrl;

      if (this.channelFile.file !== null) {
        const file = this.channelFile.file;

        const res = await uploadFile({ file, type: 'image' });
        logoUrl = res.data.url;
      }

      const channelObject = {
        name: this.channel.name,
        type: this.channel.type,
        description: this.channel.description,
        logoUrl,
        participants: {
          data: participantIds.map((id) => ({ participant_id: id })),
        },
      };

      createChannel(channelObject)
        .then((response) => {
          this.loading = false;

          const channel = response.data.insert_chat_channel.returning[0];

          // this.$router.push('/app/messaging');
          this.$router.push({
            name: 'ChannelMessaging',
            query: { channel: channel.name + channel.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    onchangePublic(v) {
      this.isPrivate = false;
      this.channel.type = v === true ? 'OPEN' : 'CLOSE';
    },
    onchangePrivate(v) {
      this.isPublic = false;
      this.channel.type = v === true ? 'CLOSE' : 'OPEN';
    },
    addParticipants(usersArray) {
      console.log(usersArray);
      // remove existing participants
      usersArray = usersArray.filter((user) => {
        return !this.participants.find(
          (participant) => participant.id === user.id
        );
      });
      this.participants = [...this.participants, ...usersArray];
    },
    removeParticipants(userId) {
      this.participants = this.participants.filter(
        (user) => user.id !== userId
      );
    },
    openImageChange() {
      this.$refs.channelFileInput.click();
    },
    async onImageChange(e) {
      const file = e.target.files[0];
      if (file && file.type.includes('image')) {
        console.log(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.channelFile.url = e.target.result;
        };
        reader.readAsDataURL(file);

        this.channelFile.file = file;
      } else {
        this.$toast({
          title: 'Unsupported file type',
          description: `Only image files are allowed.`,
          status: 'warning',
          position: 'top',
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create_chanel {
  padding: 0 20px;
  overflow-y: scroll;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 39px;
    color: var(--color-primary);
  }

  &__name {
    height: 146px;
    display: flex;
    align-items: center;
    background: rgba(66, 103, 178, 0.14);
    border-radius: 16px;
    margin-top: 20px;
    padding-left: 25px;

    .image_span {
      display: flex;
      height: 100px;
      width: 100px;
      align-items: center;
      justify-content: center;
      background: rgba(147, 169, 214, 0.26);
      border-radius: 50%;
      margin-right: 20px;

      svg {
        font-size: 30px;
        color: var(--color-secondary);
      }

      img {
        height: 100%;
        width: 100px;
        background-size: contain;
        border-radius: 50%;
      }
    }

    .chan_name {
      min-width: 300px;
      width: 100%;
      font-weight: bold;
    }

    .text_area {
      min-width: 300px;
      width: 100%;
      height: 70px;
      background: transparent;
      border: none;
      outline: none;
    }
  }

  &__members {
    background: rgba(66, 103, 178, 0.14);
    border-radius: 16px;

    svg {
      font-size: 27px;
      color: var(--color-secondary);
    }

    p {
      color: var(--color-primary);
      font-size: 16px;
      font-weight: bold;
      margin-left: 10px;
      cursor: pointer;
    }

    hr {
      height: 2px;
      background: rgba(66, 103, 178, 0.14);
      margin-top: 10px;
    }
  }
}

.css-132bqlb {
  border-radius: 10px;
}

.user_container {
  padding-right: 10px;
  &:hover {
    background: #e4eaf5;
    border-radius: 3px;
  }
  .user {
    display: flex;

    margin-bottom: 3px;
    cursor: pointer;
    padding: 8px 5px;

    img {
      margin-right: 10px;
      width: 45px;
      height: 45px;
      object-fit: fill;
    }

    img.recent {
      border-radius: 50%;
    }
    img.members {
      border-radius: 8px;
    }

    .details {
      h3 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-primary);
      }

      p {
        margin-top: 3px;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: var(--color-primary);
      }
    }
  }
}
</style>
